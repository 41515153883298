@import "../../App.css";

#game-main{
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #434343;
}

#game-top {
    display: flex;
    flex-direction: row;
    width: 90%;
}

/* width */
/* ::-webkit-scrollbar {
    width: 20px;
  } */
  
  /* Track */
  /* ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  } */
  
  /* Handle */
  /* ::-webkit-scrollbar-thumb {
    background: red;
    border-radius: 10px;
  } */

#game-top-img {
    display: flex;
    align-items: top;
    width: 50%;
}

#game-info {
    width: 50%;
}

#main-img {
    width: 500px;
    object-fit: contain;
}

#more-info {
    display: flex;
    flex-direction: column;
    padding: 0.25rem 1.5rem 0.25rem 1.5rem;
    margin: 0.5rem;
    border-radius: 1rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    background-color: #e2ecf8;
    color: #434343;
    transition: .5s ease;
    text-align: start;
}