@import "../../App.css";

nav {
	display: flex;
	max-height: 100px;
	top: 0;
	justify-content: space-between;
	align-items: center;
	background-color: white;
	border-color: transparent;
	border-bottom-color: rgba(0, 0, 0, 0.2);
	border-style: solid;
	font-weight: bold;
}

.navLogo {
	width: 150px;
	padding-left: 2rem;
}

ul {
	display: flex;
}

ul li {
	list-style: none;
}

ul li a {
	display: block;
	color: var(--purple);
	text-decoration: none;
	padding: 0.5rem;
	margin: 0 1rem 0 0;
}
   
ul li a:hover {
	color: #f1c232ff;
}
   
ul li a.active {
	color: white;
	background-color: var(--purple);
	box-shadow: 0 4px 2px -2px rgba(0,0,0,.2);
	border-radius: 0.25rem;
}

@media (max-width:480px) {

}