:root {
  --purple: #674ea7ff;
}

.main-container {
  max-width: 1080px;
  margin: auto;
  padding-top: 32px;
  padding-bottom: 16px;
}
