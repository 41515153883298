/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

@font-face {
	font-family: "MainFont";
	src: url("./Comfortaa-VariableFont_wght.ttf");
	font-weight: normal;
	font-style: normal;
}

body {
  background-color: #f4f5f7;
  margin: 0;
  width: 100%;
  font-family: "MainFont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
