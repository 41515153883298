@import "../../App.css";

#game-card-container {
    display: flex;
    background-color: #e2ecf8;
    padding: 0.25rem 1.5rem 0.25rem 1.5rem;
    margin: 0.5rem;
    /* align-items: center; */
    border-radius: 1rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: .5s ease;
    text-align: start;
}

#game-card-container:hover {
    -webkit-transition: transform 0.25s ease-in-out;
    -ms-transition: transform 0.25s ease-in-out;
    transform: scale(1.025);
}

#game-card-container p {
    font-size: 13px;
}

#game-info {
    display: block;
    align-items: start;
    width: 40rem;
}

a {
    text-decoration: none;
    color: #434343;
}

img {
    width: 11rem;
    padding: 0.75rem 2rem 0.75rem 0rem;
}