#footer-container {
	padding-left: 0.5rem;
	background-color: white;
	border-color: transparent;
	border-top-color: rgba(0, 0, 0, 0.2);
	border-style: solid;
    display: flex;
    bottom: 0;
	align-items: center;
	justify-content: center;
	text-align: center;
}