@import "../../App.css";

.search-main {
    color: #e2ecf8;
    font-weight: bold;
    border-radius: 2rem;
    justify-content: center;
    vertical-align: top;
    text-align: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
	background-color: #434343;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: .5s ease;
}

.search-main:hover {
    -webkit-transition: transform 0.25s ease-in-out;
    -ms-transition: transform 0.25s ease-in-out;
    transform: scale(1.025);
}